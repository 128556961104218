import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

export default function(props) {
	return (
		<Link to={props.photo.url}>
			<div
				style={{
					margin: props.margin,
					height: props.photo.height,
					width: props.photo.width,
					left: props.left,
					position: "absolute",
					top: props.top,
				}}
				className={styles[props.photo.type]}
			>
				{!props.photo.src && (
					<div
						className={styles.postExcerpt}
						dangerouslySetInnerHTML={{
							__html: props.photo.text,
						}}
					/>
				)}
				<h5 className={styles.title}>
					<span>{props.photo.title}</span>
				</h5>
				{props.photo.src && (
					<img
						height={props.photo.height}
						width={props.photo.width}
						src={props.photo.src}
					/>
				)}
			</div>
		</Link>
	);
}
