import React from 'react';
import styles from './styles.module.css';

export default function Ribbon({children}) {
	return (
		<div className={styles.ribbon}>
			<div className={styles.ribbonInner}>
				<div className={styles.ribbonTailLeft}/>
				<div className={styles.ribbonTailRight}/>
				<div className={styles.ribbonText}>
					{children}
				</div>
			</div>
		</div>
	);
}