import React from "react";
import { useLazyQuery } from "@apollo/client";
import Inview from "components/Inview";
import Loading from "components/Loading";

export default ({
	opts,
	query,
	children,
	fullpage = false,
	triggerWhenInView = false,
}) => {
	const hoc = triggerWhenInView ? Inview : Compo => () => <Compo inview />;

	const Component = hoc(({ inview }) => {
		const [trigger, result] = useLazyQuery(query, opts);
		const { loading, error, data } = result;
		React.useEffect(() => {
			if (inview) {
				trigger();
			}
		}, [inview]);
		if (loading) return <Loading fullpage={fullpage} />;
		if (error) return null;
		if (!data) return <span />;

		return children(result);
	});
	return <Component />;
};
