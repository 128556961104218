import React from "react";
import styles from "./styles.module.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default function(props) {
	const [focus, setFocus] = React.useState(false);
	const credit = props.photo.credit && (
		<div>
			Pic by{" "}
			{props.photo.credit.link ? (
				<a target="_blank" href={props.photo.credit.link}>
					{props.photo.credit.name}
				</a>
			) : (
				<span>{props.photo.credit.name}</span>
			)}
		</div>
	);
	return (
		<div
			style={{
				margin: props.margin,
				height: props.photo.height,
				width: props.photo.width,
				left: props.left,
				position: "absolute",
				top: props.top,
			}}
			onClick={() => setFocus(true)}
			className={styles[props.photo.type]}
		>
			<h5 className={styles.title}>
				<span>{props.photo.title}</span>
			</h5>
			<img
				src={props.photo.src}
				height={props.photo.height}
				width={props.photo.width}
			/>
			{focus && (
				<Lightbox
					mainSrc={props.photo.bigPic}
					onCloseRequest={() => setFocus(false)}
					imageTitle={props.photo.title}
					imageCaption={credit}
				/>
			)}
		</div>
	);
}
