import React from 'react';
import styles from './page.css';
import Loadable from 'react-loadable';
import Loading from '../components/Loading';

export default function Page(loader) {
	const LoadableComponent = Loadable({
		loader,
		loading: Loading,
	});

	return ({match}) =>
		<div className={styles.main}>
			<LoadableComponent match={match} />
		</div>;
}
