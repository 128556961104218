import React from "react";
import { gql } from "@apollo/client";
import PostPageComponent from "components/PostPageComponent";
import LoadingQuery from "components/LoadingQuery";

const query = gql`
	query Post($slug: String!) {
		post(slug: $slug) {
			title
			slug
			publishedDate
			content {
				extended
				brief
			}
			categories {
				name
				key
			}
			image {
				image {
					public_id
					version
					signature
					format
					resource_type
					url
					width
					height
					secure_url
				}
				name
				publishedDate
				description
			}
			next {
				title
				slug
				publishedDate
				content {
					brief
				}
			}
			previous {
				title
				slug
				publishedDate
				content {
					brief
				}
			}
		}
	}
`;

export default ({
	match: {
		params: { slug },
	},
}) => {
	return (
		<LoadingQuery query={query} fullpage opts={{ variables: { slug } }}>
			{({ data }) => {
				if (!data.post) return null;
				return <PostPageComponent {...data.post} />;
			}}
		</LoadingQuery>
	);
};
