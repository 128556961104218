import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { FaMusic } from "react-icons/fa";

export default function(props) {
	const inner = (
		<div
			style={{
				margin: props.margin,
				height: props.photo.height,
				width: props.photo.width,
				left: props.left,
				position: "absolute",
				top: props.top,
			}}
			className={styles[props.photo.type]}
		>
			{!props.photo.src && (
				<div
					className={styles.homepageTileExcerpt}
					dangerouslySetInnerHTML={{
						__html: props.photo.text,
					}}
				/>
			)}
			{props.photo.type === "TUNE" && <FaMusic className={styles.icon} />}
			<h5 className={styles.title}>
				<span>{props.photo.title}</span>
			</h5>
			{props.photo.src && (
				<img
					height={props.photo.height}
					width={props.photo.width}
					src={props.photo.src}
				/>
			)}
		</div>
	);

	return props.photo.url ? <Link to={props.photo.url}>{inner}</Link> : inner;
}
