import React, { useState, useEffect } from "react";
import MenuBar from "../../components/MenuBar";
import Ribbon from "../../components/Ribbon";
import keys from "./img/keys.jpg";
import fox from "./fox-smlr.jpg";
import classnames from "classnames";
import HomepageTiles from "components/HomepageTiles";
import Para from "components/Para";
import ParallaxImage from "components/ParallaxImage";
import { Link } from "react-router-dom";

import styles from "./homepage.module.css";

function TitleLetter({ children }) {
	return <span className={styles.TitleLetter}>{children}</span>;
}

export default () => {
	const [logoLoaded, setLogoLoaded] = useState(false);
	useEffect(() => {
		const image = new Image();
		image.onload = () => setLogoLoaded(true);
		image.src = keys;
	}, [logoLoaded, setLogoLoaded]);
	return (
		<div className={styles.homepage}>
			<div className={styles.gradientAnim}>
				<div className={styles.logoWrapper}>
					{logoLoaded && (
						<img
							alt="logo"
							className={classnames(
								styles.AppLogo,
								styles.loaded
							)}
							src={keys}
						/>
					)}
				</div>
				<h1 className={styles.AppTitle}>Foxxo! :3</h1>
				<h6 className={styles.AppTitleSubheading}>Keys The Fox</h6>
				<MenuBar />
			</div>
			<div className={styles.contentWrap}>
				<h2 className={styles.hello}>Hello!</h2>
				<Para>
					<Ribbon>Uh I think so</Ribbon>
					<TitleLetter>W</TitleLetter>elcome, idk what I put here but
					I keep updating this line of text every now and then anyway
					so hi.
				</Para>
				<Para>
					<TitleLetter>I</TitleLetter>'m a fluffy sparkle foxxo,
					hatched from an egg in the deep mystical woods of outermost
					London.
				</Para>
				<div style={{ margin: "1em", fontSize: "3em" }}>
					<Link to="/foxxo-cube">Heres some thing :3</Link>
				</div>
				<Para>
					<TitleLetter>I</TitleLetter> do a lot of music &
					programming, a lot of the time at the same time somehow.
				</Para>
				<ParallaxImage url={fox} title=":3" credits="Londonfurs" />
				<div className={styles.recentFooter}>
					<HomepageTiles />
				</div>
			</div>
		</div>
	);
};
