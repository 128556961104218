import React from "react";
import styles from "./styles.module.css";

export default function(props) {
	const src = `https://www.youtube.com/embed/${props.photo.slug}`;
	return (
		<div
			style={{
				margin: props.margin,
				height: props.photo.height,
				width: props.photo.width,
				left: props.left,
				position: "absolute",
				top: props.top,
			}}
			className={styles[props.photo.type]}
		>
			<iframe
				height={props.photo.height}
				width={props.photo.width}
				src={src}
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	);
}
