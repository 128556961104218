import React from "react";
import styles from "./styles.module.css";
import dateFormat from "utils/dateFormat";
import { Link } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

function PostPageComponent({
	title,
	slug,
	content: { extended },
	publishedDate,
	image,
	previous,
	next,
	categories,
}) {
	const { secure_url } = image || {};
	return (
		<>
			<div className={styles.wrapper}>
				<div
					className={styles.headingWrap}
					style={{ backgroundImage: `url(${secure_url})` }}
				>
					<div className={styles.headingWrapInner}>
						<h2 className={styles.header}>{title}</h2>
						<h5 className={styles.subHeader}>
							{dateFormat(publishedDate)}
						</h5>
					</div>
				</div>
				<div
					dangerouslySetInnerHTML={{ __html: extended }}
					className={styles.content}
				/>
				<div className={styles.categories}>
					{categories.map(({ name, key }) => (
						<span key={key} className={styles.category}>
							{name}
						</span>
					))}
				</div>
			</div>
			<div className={styles.sequence}>
				{next && (
					<Link to={`/post/${next.slug}`} className={styles.next}>
						<FaArrowLeft className={styles.arrow} />
						<div>
							<h4 className={styles.prevNextHeaders}>
								{next.title}
							</h4>
							<h5 className={styles.prevNextHeaders}>
								{dateFormat(next.publishedDate)}
							</h5>
						</div>
					</Link>
				)}

				{previous && (
					<Link
						to={`/post/${previous.slug}`}
						className={styles.previous}
					>
						<div>
							<h4 className={styles.prevNextHeaders}>
								{previous.title}
							</h4>
							<h5 className={styles.prevNextHeaders}>
								{dateFormat(previous.publishedDate)}
							</h5>
						</div>
						<FaArrowRight className={styles.arrow} />
					</Link>
				)}
			</div>
		</>
	);
}

export default PostPageComponent;
