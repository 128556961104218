import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import styles from "./styles.module.css";
import Notation from "components/Notation";

const query = gql`
	{
		notations {
			title
			abc
			notes
		}
	}
`;

export default () => {
	const { loading, error, data } = useQuery(query);
	const [selected, setSelected] = useState();
	if (loading) return null;
	if (error) return null;

	return (
		<div className={styles.notationsContainer}>
			{data.notations.map(data => (
				<Notation
					{...data}
					selected={selected === data.title}
					setSelected={() => {
						if (data.title === selected) {
							setSelected();
						} else {
							setSelected(data.title);
						}
					}}
				/>
			))}
			<div className={styles.great}>
				<div />
			</div>
		</div>
	);
};
