import React, { useEffect, useState, useRef } from 'react';
import ABCJS from 'abcjs';
import classnames from 'classnames';
import styles from './styles.module.css';

export default ({
	title,
	abc,
	notes,
	selected,
	setSelected,
	date,
	subtitle,
}) => {
	const [offset] = useState(Math.round(Math.random() * 4) - 2);
	const ref = useRef();
	const id = `tune-${title}`;
	useEffect(() => {
		if (selected) {
			ABCJS.renderAbc(id, abc, {
				responsive: 'resize',
			});
		}
	}, [abc, selected]);

	useEffect(() => {
		if (ref.current) {
			ref.current.scroll(0, 0);
		}
	}, [ref, selected]);

	return (
		<div className={classnames({ [styles.notationWrapper]: selected })}>
			<div
				className={classnames(styles.notation, {
					[styles.selected]: selected,
				})}
				style={{
					transform: selected ? 'rotateX(0)' : 'rotateX(-1deg)',
				}}
				ref={ref}
			>
				<h4 className={styles.header} onClick={setSelected}>
					{title}
				</h4>
				{subtitle && <h6 className={styles.subtitle}>{subtitle}</h6>}
				{date && <h6 className={styles.subtitle}>{date}</h6>}
				<div id={id} className={styles.tuneContainer} />
				<div className={styles.notes}>{notes}</div>
			</div>
		</div>
	);
};
