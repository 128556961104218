import React from 'react';
import { throttle } from 'throttle-debounce';
const INITIAL_SCREEN_BLANK = 200;
export default Component =>
	class Inview extends React.Component {
		constructor() {
			super();
			this.state = { inview: false };
			this.wrapperRef = React.createRef();
		}

		setBounds() {
			if (this.wrapperRef.current) {
				const {
					y,
					height,
				} = this.wrapperRef.current.getBoundingClientRect();
				this.setState({
					...this.state,
					yPos: y + window.scrollY,
					yPosMax: y + window.scrollY + height,
				});
			}
		}

		componentDidMount() {
			this.setBounds();
			const throttledScroll = throttle(45, this.handleScroll);
			window.addEventListener('scroll', () => throttledScroll());
			window.setTimeout(throttledScroll, INITIAL_SCREEN_BLANK);
		}

		handleScroll = () => {
			this.setBounds();
			const { inview } = this.state;
			const topOfScreen = window.scrollY;
			const bottomOfScreen = window.scrollY + window.innerHeight;
			if (inview) {
				if (topOfScreen > this.state.yPosMax) {
					this.setState({
						...this.state,
						inview: false,
					});
				}
				if (bottomOfScreen < this.state.yPos) {
					this.setState({
						...this.state,
						inview: false,
					});
				}
			} else {
				if (
					topOfScreen < this.state.yPosMax &&
					bottomOfScreen > this.state.yPos
				) {
					this.setState({
						...this.state,
						inview: true,
					});
				}
			}
		};

		render() {
			return (
				<div ref={this.wrapperRef}>
					<Component inview={this.state.inview} {...this.props} />
				</div>
			);
		}
	};
