import React from 'react';
import styles from './styles.module.css';
import Heading from 'components/Heading';
import Para from 'components/Para';
import Notations from 'components/Notations';

export default () => (
	<div className={styles.wrapper}>
		<Para>
			<Heading>Tunes</Heading>
			<p>
				I make music, here's some of the tunes I've written for solo
				accordion in ABC format
			</p>
			<p>
				Other formats exist, but I chose this one because it has a cool
				text-based language
			</p>
			<p>
				Plus because I found a svg renderer library that dazzles this
				data into delightful svgs
			</p>
			<p>
				Then I got far too into making whatever the below is supposed to
				be:
			</p>
			<Notations />
		</Para>
	</div>
);
