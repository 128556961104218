import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

export default () => (
	<>
		<Button As={Link} to={`/`}>
			Home
		</Button>
		<Button As={Link} to={`/post`}>
			Posts
		</Button>
		{/* <Button As={Link} to={`/projects`}>
			Games
		</Button>
		<Button As={Link} to={`/music`}>
			Music
		</Button> */}
	</>
);
