import React from 'react';
import styles from './styles.module.css';
import Inview from 'components/Inview';

export default Inview(function ({children, inview, subHeader}) {
	return (
		<>
			<h2 className={styles.facts + ' ' + (inview ? styles.inviewWobble:'')}>
				{children}
				<div className={styles.factsRainbowBar}>
					<div className={styles.factsRainbowBarInner}/>
				</div>
			</h2>
			{subHeader && <h5 className={styles.subheading}>{subHeader}</h5>}
		</>
	);
});
