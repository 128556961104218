import React from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./Page";
import NotFound from "./NotFound";
import PostPage from "./PostPage";
import HomePage from "./HomePage";
import MenuBar from "components/MenuBar";
import Recent from "./Recent";
import Tunes from "./Tunes";

const Music = Page(() => import("./Music" /* webpackChunkName: "music" */));
const Main = Page(() => import("./Main" /* webpackChunkName: "Main" */));
const Psi = Page(() => import("./Psi" /* webpackChunkName: "Psi" */));
const Nothing = Page(() =>
	import("./Nothing" /* webpackChunkName: "Nothing" */)
);

export default () => (
	<>
		<Switch>
			<Route exact path="/" render={() => null} />
			<Route component={MenuBar} />
		</Switch>
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/main" component={Main} />
			{/* <Route path="/music" component={Music} /> */}
			<Route exact path="/psi" component={Psi} />
			<Route exact path="/foxxo-cube" component={Nothing} />
			<Route exact path="/post/:slug" component={PostPage} />
			<Route exact path="/post" component={Recent} />
			<Route exact path="/tunes" component={Tunes} />
			<Route component={NotFound} />
		</Switch>
	</>
);
