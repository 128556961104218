export default function(url = '') {
	if (url.match(/gif/)) {
		return {
			small: url,
			medium: url,
			large: url,
		};
	}
	const [prefix, suffix] = url.split('/upload/');
	return {
		small: `${prefix}/upload/w_320/${suffix}`,
		medium: `${prefix}/upload/w_640/${suffix}`,
		large: `${prefix}/upload/w_960/${suffix}`,
	};
}
