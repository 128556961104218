import React, { Component } from "react";
import styles from "./App.module.css";
import Routes from "./Routes";
import ScrollToTop from "components/ScrollToTop";
import Keys from "components/Keys";
import Noodle from "components/Noodle";
import cloud from "./cloud.png";

class App extends Component {
	render() {
		return (
			<>
				<div className={styles.App}>
					<ScrollToTop>
						<Routes />
					</ScrollToTop>
				</div>
				<img src={cloud} className={styles.cloud} />
				<Keys />
				<Noodle />
				<footer className={styles.footer}>
					<div>
						foxxo.co.uk is a very small flower in a very large field
					</div>
					<div className={styles.love}>🐉❤️🦊</div>
					<div>
						Smell the pungent aroma of this freshly served webpage
					</div>
					<div className={styles.blocks} />
				</footer>
				<div className={styles.sea} />
			</>
		);
	}
}

export default App;
