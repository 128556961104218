import React from "react";
import RecentPosts from "components/RecentPosts";
import styles from "./styles.module.css";

export default () => (
	<div className={styles.postContainer}>
		<h4 className={styles.heading}>Recent guff</h4>
		<RecentPosts />
	</div>
);
