import React from 'react';
import styles from './styles.module.css';
import MenuItems from 'components/MenuItems';

export default class MenuBar extends React.Component {
	constructor() {
		super();
		this.state = { floating: false };
		this.floatRef = React.createRef();
	}

	componentDidMount() {
		if (this.floatRef.current) {
			const { y } = this.floatRef.current.getBoundingClientRect();
			this.setState({
				...this.state,
				yPos: y + window.scrollY,
			});
		}
		window.addEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const { floating } = this.state;
		if (!floating && window.scrollY > this.state.yPos) {
			this.setState({
				...this.state,
				floating: true,
			});
		}
		if (floating && window.scrollY < this.state.yPos) {
			this.setState({
				...this.state,
				floating: false,
			});
		}
	};
	render() {
		return (
			<div ref={this.floatRef} className={styles.container}>
				<div
					className={
						this.state.floating
							? styles.floating
							: styles.notFloating
					}
				>
					<div className={styles.floatingInner}>
						<div className={styles.floatingInnerInner}>
							<MenuItems />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
