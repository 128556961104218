import React from "react";
import { gql } from "@apollo/client";
import LoadingQuery from "components/LoadingQuery";
import Gallery from "react-photo-gallery";
import getImageSizes from "utils/getImageSizes";

import PhotoTile from "./PhotoTile";
import TuneTile from "./TuneTile";
import PostTile from "./PostTile";
import VideoTile from "./VideoTile";

const TYPE_COMPONENT_MAP = {
	IMAGE: PhotoTile,
	POST: PostTile,
	TUNE: TuneTile,
	VIDEO: VideoTile,
};

function getDefaults(type) {
	switch (type) {
		case "TUNE":
			return { defaultWidth: 3, defaultHeight: 1 };
		case "VIDEO":
			return { defaultWidth: 16, defaultHeight: 9 };
		default:
			return { defaultWidth: 2, defaultHeight: 1 };
	}
}

const query = gql`
	{
		homepageTiles {
			title
			type
			slug
			image {
				public_id
				version
				signature
				format
				resource_type
				url
				width
				height
				secure_url
			}
			text
			credit {
				name
				link
			}
		}
	}
`;

function columns(containerWidth) {
	let columns = 1;
	if (containerWidth >= 600) columns = 2;

	if (containerWidth >= 900) columns = 3;

	return columns;
}

const CustomComponent = props => {
	const Component = TYPE_COMPONENT_MAP[props.photo.type] || (() => null);
	return <Component key={props.key} {...props} />;
};

function formatUrl(homepageTile) {
	switch (homepageTile.type) {
		case "POST":
			return `/post/${homepageTile.slug}`;
		case "TUNE":
			return `/tune/${homepageTile.slug}`;
		default:
			return "";
	}
}

export default () => {
	return (
		<LoadingQuery query={query} triggerWhenInView>
			{({ data }) => {
				if (!data.homepageTiles) return null;
				const photos = data.homepageTiles.map(homepageTile => {
					const { defaultWidth, defaultHeight } = getDefaults(
						homepageTile.type
					);
					const { secure_url, width, height } =
						homepageTile.image || {};

					return {
						key: `${homepageTile.slug}-${homepageTile.title}`,
						src: secure_url && getImageSizes(secure_url).small,
						bigPic: secure_url && getImageSizes(secure_url).large,
						width: width || defaultWidth,
						slug: homepageTile.slug,
						height: height || defaultHeight,
						title: homepageTile.title,
						type: homepageTile.type,
						text: homepageTile.text,
						credit: homepageTile.credit,
						url: formatUrl(homepageTile),
					};
				});

				return (
					<Gallery
						targetRowHeight={10}
						photos={photos}
						direction="column"
						renderImage={CustomComponent}
						columns={columns}
					/>
				);
			}}
		</LoadingQuery>
	);
};
