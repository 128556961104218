import React, { useState, useEffect } from 'react';
import noodleImg from './drae.gif';
import styles from './styles.module.css';
import classnames from 'classnames';

const INIT_NOODLE = () => ({
	y: 10 + Math.round(Math.random() * 80),
	distant: !!(Math.random() > 0.5),
});
export default () => {
	const [noodle, setNoodle] = useState(INIT_NOODLE());

	useEffect(() => {
		if (noodle) {
			setTimeout(() => {
				setNoodle();
			}, 8000);
		} else {
			setTimeout(
				() => setNoodle(INIT_NOODLE()),
				Math.random() * 60000 + 25000
			);
		}
	}, [noodle]);

	return noodle ? (
		<img
			src={noodleImg}
			className={classnames(styles.noodle, {
				[styles.distant]: noodle.distant,
			})}
			style={{ top: `${noodle.y}vh` }}
		/>
	) : null;
};
