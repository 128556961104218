import React, { useState, useEffect } from "react";
import keys from "./transkeys.png";
import styles from "./style.module.css";
import { useQuery, gql } from "@apollo/client";

import Inview from "components/Inview";
import classnames from "classnames";

const query = gql`
	{
		chunks {
			text
		}
	}
`;

const Keys = ({ text }) => {
	const words = text.split("");
	const [show, setShow] = useState(false);
	useEffect(() => {
		setShow(false);
		setTimeout(() => setShow(true), 2000);
	}, [text]);

	const InnerComponent = Inview(({ inview }) => {
		const [textState, setTextState] = useState("");
		const [wordIndex, setWordIndex] = useState(0);
		const isDancing = text.indexOf("dance") >= 0;

		useEffect(() => {
			if (inview && wordIndex < words.length) {
				const newWord = words[wordIndex];
				setTimeout(
					() => {
						const newState = textState + newWord;
						setTextState(newState);
						setWordIndex(wordIndex + 1);
					},
					",.?!".indexOf(newWord) >= 0
						? 300
						: " ".indexOf(newWord) >= 0
						? 120
						: 30
				);
			}
		}, [inview, wordIndex]);
		return (
			inview && (
				<div className={styles.relative}>
					<div className={styles.chat}>
						<div>{textState}</div>
					</div>
					<img
						className={classnames(styles.image, {
							[styles.dance]: isDancing,
						})}
						src={keys}
					/>
				</div>
			)
		);
	});

	return (
		show && (
			<div className={styles.wrapper}>
				<InnerComponent />
			</div>
		)
	);
};

export default () => {
	const { loading, error, data } = useQuery(query);
	if (loading) return null;
	if (error) return null;

	const chunk = data.chunks[Math.floor(Math.random() * data.chunks.length)];
	return chunk ? <Keys text={chunk.text} /> : null;
};
