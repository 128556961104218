import React from "react";
import { gql } from "@apollo/client";
import Post from "components/Post";
import LoadingQuery from "components/LoadingQuery";

const query = gql`
	{
		posts {
			title
			slug
			publishedDate
			content {
				extended
				brief
			}
			image {
				image {
					public_id
					version
					signature
					format
					resource_type
					url
					width
					height
					secure_url
				}
				name
				publishedDate
				description
			}
		}
	}
`;

export default () => {
	return (
		<LoadingQuery query={query} fullpage>
			{({ data }) => {
				return (
					<>
						{data.posts.map(post => (
							<Post key={post.slug} {...post} />
						))}
					</>
				);
			}}
		</LoadingQuery>
	);
};
