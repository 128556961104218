import React from "react";
import styles from "./styles.module.css";
import Inview from "components/Inview";
import classnames from "classnames";

export default Inview(function({ children, inview, style }) {
	return (
		<div
			className={classnames(styles.content, {
				[styles.inviewFade]: inview,
			})}
			style={style}
		>
			<div>{children}</div>
		</div>
	);
});
