import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

import Heading from "components/Heading";
import Para from "components/Para";
import ParallaxImage from "components/ParallaxImage";
import dateFormat from "utils/dateFormat";
import { FaExternalLinkAlt } from "react-icons/fa";

function Post({ title, slug, content: { brief }, publishedDate, image }) {
	const { name, description } = image || {};
	const { secure_url } = (image || {}).image || {};

	return (
		<Link to={`/post/${slug}`} className={styles.wrapper}>
			<ParallaxImage
				url={secure_url}
				title={name}
				credits={description}
			/>
			<Para className={styles.content} style={{ overflow: "auto" }}>
				<Heading subHeader={dateFormat(publishedDate)}>{title}</Heading>
				<div dangerouslySetInnerHTML={{ __html: brief }} />
				<FaExternalLinkAlt className={styles.link} />
			</Para>
		</Link>
	);
}
export default Post;
