import React from 'react';
import styles from './styles.module.css';
export default function ParallaxImage({url, title, credits}) {
	if (!url) {
		return null;
	}

	return (
		<div className={styles.parallax} style={{backgroundImage: `url(${url})`}}>
			{title && <div className={styles.parallaxTitle}>
				<div>{title}</div>
				{credits && <div className={styles.parallaxCredits}>{credits}</div>}
			</div>}
		</div>
	);
}